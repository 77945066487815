@font-face {
font-family: '__Jost_e4abf7';
src: url(/_next/static/media/b39aaa38d13114ac-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__Jost_Fallback_e4abf7';src: local("Arial");ascent-override: 112.02%;descent-override: 39.26%;line-gap-override: 0.00%;size-adjust: 95.51%
}.__className_e4abf7 {font-family: '__Jost_e4abf7', '__Jost_Fallback_e4abf7';font-weight: 400
}.__variable_e4abf7 {--font-jost: '__Jost_e4abf7', '__Jost_Fallback_e4abf7'
}

